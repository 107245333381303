import { LayoutBox, Section } from '@bamboohr/fabric';

import { FunctionComponent } from 'react';

const FieldSet: FunctionComponent<DynamicForm.FieldSetElementProps> = (fieldSetProps) => {
	const { props, children } = fieldSetProps;
	const { className = '' } = props;
	const noSectionClassName = 'js-noSection';

	return (
        <fieldset {...props} className={className}>
            {className.includes(noSectionClassName) ?
                <LayoutBox marginTop='20px'>
                    {children}
                </LayoutBox>
                :
                <LayoutBox marginTop={4}>
                    <Section>
                        {children}
                    </Section>
                </LayoutBox>
			}
        </fieldset>
    );
};

export default FieldSet;
