import { Button, StandardModal, TextButton } from '@bamboohr/fabric';

import { noop } from 'lodash';
import { FunctionComponent } from 'react';

type Props = {
	handleClose: Function;
	handlePrimaryAction: Function;
	data: {
		contactName: string;
		contactRelationship?: string;
	};
};

const DeleteEmergencyContactModal: FunctionComponent<Props> = ({ handleClose = noop, handlePrimaryAction = noop, data }) => {
	return (
		<StandardModal isOpen={true} onRequestClose={(): void => handleClose()}>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<TextButton key='secondary' onClick={(): void => handleClose()} type='button'>
								{$.__('Keep Contact')}
							</TextButton>,
							<Button key='primary' onClick={(): void => handlePrimaryAction()} type='button'>
								{$.__('Delete Contact')}
							</Button>,
						]}
					/>
				}
			>
				<StandardModal.Constraint>
					<StandardModal.HeroHeadline
						icon='trash-can-regular'
						iconColor='error-strong'
						text={$.__('Are you sure you want to delete this contact?')}
					/>
					<div className='Contact__modalContent'>
						<div className='Contact__modalName'>{data.contactName}</div>
						<div className='Contact__modalRelationship'>{data.contactRelationship}</div>
					</div>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
};

export default DeleteEmergencyContactModal;
