import './currency.styl';

import { CurrencyPickerProvider, CurrencyField as FabricCurrencyField } from '@bamboohr/fabric';
import {
	Fields,
	currentEditingEmployeeId,
	fixValidationRegisterName,
	getClassBasedValidation,
	getPayScheduleListValueId,
	handleControlledInput,
	hasErrors,
	isRequired,
	isSelectedPSSyncing,
} from 'dynamic-form';
import { FunctionComponent, useEffect, useState } from 'react';

import Ajax from '@utils/ajax';
import classNames from 'classnames';
import { useNonInitialEffect } from 'base/_hooks/useNonInitialEffect';

const CurrencyField: FunctionComponent<DynamicForm.TextElementProps> = (currencyFieldProps) => {
	const { props, settings, context } = currencyFieldProps;
	const {
		controls: { TextField, PendingRequest },
		validation: {
			formState: { errors },
			register,
			setValue,
			trigger,
		},
	} = context;
	// if calculated field and un-editable it comes through as readOnly not disabled
	const { disabled, readOnly, name, id, value, className } = props;
	const { currency } = settings;
	const isDisabled = !!disabled;

	const [currencyItems, setCurrencyItems] = useState([]);
	const [currencyFormat, setCurrencyFormat] = useState(currency);
	const [currencyValue, setCurrencyValue] = useState(value);

	const validationName = fixValidationRegisterName(name);
	const hasError = hasErrors(errors, validationName);
	register(validationName, {
		...getClassBasedValidation(context, currencyFieldProps),
		required: isRequired(context, currencyFieldProps),
	});

	const isSyncing = isSelectedPSSyncing(context);

	useEffect(() => {
		setValue(validationName, value);
		getCurrencyItems();
	}, [isSyncing]);

	useNonInitialEffect(() => {
		if (currency.code !== currencyFormat.code) {
			handleControlledInput('currency-object', id, currencyFormat, context);
			setTimeout(() => {
				trigger(validationName);
			}, 1);
		}
	}, [currencyFormat]);

	useNonInitialEffect(() => {
		handleControlledInput('text', id, currencyValue.toString(), context);
	}, [currencyValue]);

	const getCurrencyItems = (): void => {
		let employeeId = null;
		let payScheduleId = null;

		// only restrict currency if syncing and is OR or PR
		if (isSyncing && (className.includes(Fields.overtimeRate) || className.includes(Fields.payRate))) {
			employeeId = currentEditingEmployeeId();
			payScheduleId = getPayScheduleListValueId(context);
		}

		Ajax.get('/ajax/get_currency', { field: id, employeeId, payScheduleId })
			.then((response) => {
				if (response.data && response.data.data && Array.isArray(response.data.data)) {
					setCurrencyItems(response.data.data);
				}
			})
			.catch(() => {
				window.setMessage($.__('There was a problem getting the currency list'), 'error');
			});

		// Initially show with loading message while Ajax is going
		setCurrencyItems([
			{
				text: $.__('Loading...'),
				code: 'TMP',
				key: 'tempLoading',
				isDisabled: true,
			},
		]);
	};





	const handleAmountAndCurrencyCodeChange = ({ amount, currencyCode }) => {
		setCurrencyValue(amount);
		setCurrencyFormat(currencyItems.find((item) => item.code === currencyCode));
		setValue(validationName, amount, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const inputProps = {
		...props,
		className: classNames(className, {
			'fab-TextInput--error': hasError,
			'fab-TextInput--disabled': disabled || readOnly,
		}),
		...((disabled || readOnly) && { readonly: String(disabled || readOnly) }),
		value: currencyValue,
	};

	const FabricCurrencyPicker = (
		<>
			<CurrencyPickerProvider
				currencyCode={currencyFormat.code}
				decimalCharacter={window.GLOBAL_DECIMAL_CHAR || '.'}
				getCurrencyItems={() => Promise.resolve(currencyItems)}
				thousandsSeparator={window.GLOBAL_THOUSANDS_SEPARATOR || ','}
			>
				<FabricCurrencyField
					id={inputProps.id}
					name={name}
					onChange={(value) => handleAmountAndCurrencyCodeChange({ amount: value.amount, currencyCode: value.currencyCode })}
					value={{ amount: currencyValue.toString(), currencyCode: currencyFormat.code }}
					size='medium'
					submitUnmaskedValue={false}
					width={6}
					disabled={disabled || readOnly}
				/>
				{name && (
					<input
						className='currencyType js-currency-picker-type'
						name={name.replace('[0]', '[1]')}
						type='hidden'
						value={currencyFormat.code}
					/>
				)}
			</CurrencyPickerProvider>
		</>
	);
		

	return (
        <TextField
			context={context}
			input={
				<div className={`CurrencyPicker ${isDisabled ? 'CurrencyPicker--pending' : ''}`}>
					<div className='fab-InputWrapper'>
						<PendingRequest context={context} props={props} settings={settings} />
						{FabricCurrencyPicker}
					</div>
				</div>
			}
			props={props}
			settings={settings}
		/>
    );
};

export default CurrencyField;
