import {
	canUseLocalStorage,
} from '@utils/localstorage';
import { MFA_JWT_REMEMBER_TOKEN_VALIDITY, MFA_JWT_REMEMBER_TOKEN_VALIDITY_MAX_RETRIES } from './constants';

import {
	validateRememberToken,
} from './service';

export const REMEMBER_TOKEN_KEY = 'remember_token';

export const trackTokenValidity = (userId, tokenExists, tokenValid, errorCode?) => {
	window.pendo?.track?.('Login | MFA | Remember Token Validity', {
		companyDomain: window.location.hostname.split('.')[0],
		userId,
		localStorageEnabled: canUseLocalStorage(),
		tokenExists,
		tokenValid,
		errorCode,
	});
}

export function getStoredRememberToken(userId?): string {
	if (canUseLocalStorage()) {
		return localStorage.getItem(REMEMBER_TOKEN_KEY);
	}
	trackTokenValidity(userId, false, null);
	return null;
}

let validateRememberTokenTries = 0;

export async function isRememberTokenValid(userId: number, rememberToken: string): Promise<MFA_JWT_REMEMBER_TOKEN_VALIDITY> {
	if (!rememberToken) {
		return MFA_JWT_REMEMBER_TOKEN_VALIDITY.INVALID;
	}

	try {
		validateRememberTokenTries++;
		const {
			status,
		} = await validateRememberToken(userId, rememberToken);

		if (status === 200) {
			validateRememberTokenTries = 0;
			trackTokenValidity(userId, true, true);
			return MFA_JWT_REMEMBER_TOKEN_VALIDITY.VALID;
		}

		return handleUnexpectedError(status);
	} catch (error) {
		if (error?.response?.status === 401 && error?.response?.data?.errorCode === 'Invalid JWT') {
			trackTokenValidity(userId, true, false, error.response.status);
			return MFA_JWT_REMEMBER_TOKEN_VALIDITY.INVALID;
		}

		return handleUnexpectedError(error?.response?.status);
	}

	function  handleUnexpectedError(errorCode) {
		if (validateRememberTokenTries < MFA_JWT_REMEMBER_TOKEN_VALIDITY_MAX_RETRIES) {
			return isRememberTokenValid(userId, rememberToken);
		}

		// if tokenValid is null (unknown), pass along the errorCode for troubleshooting
		trackTokenValidity(userId, true, null, errorCode);
		validateRememberTokenTries = 0;
		return MFA_JWT_REMEMBER_TOKEN_VALIDITY.ERROR;
	}
}

export function removeStoredRememberToken(): void {
	if (canUseLocalStorage()) {
		localStorage.removeItem(REMEMBER_TOKEN_KEY);
	}
}

export function setStoredRememberToken(value: string): void {
	if (canUseLocalStorage()) {
		localStorage.setItem(REMEMBER_TOKEN_KEY, value);
	}
}
