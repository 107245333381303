export enum MFA_LEVELS {
	ACCESS_LEVEL = 'accessLevel',
	COMPANY = 'company',
	USER_OPT_IN = 'userOptIn',
}

export const MFA_JWT_REMEMBER_TOKEN_VALIDITY_MAX_RETRIES = 3;

export enum MFA_JWT_REMEMBER_TOKEN_VALIDITY {
	VALID = 'tokenValid',
	INVALID = 'tokenInvalid',
	ERROR = 'unexpectedError',
}
