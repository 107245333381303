import { FC } from 'react';
import { BlankState, Button } from '@bamboohr/fabric';
import { DEPENDENT_BLANK_TITLE, ADD_DEPENDENT, DependentBlankStateProps } from './constants';

export const DependentBlankNewEmployee: FC<DependentBlankStateProps> = ({ onClick, canAdd }) => {
	return (
        <BlankState
            icon='bs-doc-missing-field'
            title={DEPENDENT_BLANK_TITLE}
            actions={canAdd && [
                <Button
                    color='secondary'
                    data-bi-id='employees-dependents-add-dependent'
                    onClick={onClick}
                    startIcon='plus-regular'
                    type='button'
                >
                    {ADD_DEPENDENT}
                </Button>,
            ]}
        >
        </BlankState>
    );
};
