import { IconV2, makeStyles } from '@bamboohr/fabric';
import { addRepeatableField, removeRepeatableField } from 'dynamic-form';
import { FunctionComponent, useEffect, useState } from 'react';
import { deleteEmployeeEducation } from '../api';
import DeleteEducationModal from '../modals/delete-education-modal';
import EducationGroup from './education-group';

const useStyles = makeStyles(() => ({
	button: {
		margin: '5px 0px 0px 24px'
	},
}));


const EducationRepeatContainer: FunctionComponent<DynamicForm.RepeatContainerElementProps> = ({
	props,
	settings,
	context,
	children,
}) => {
	const { id, disabled } = props;
	const { canAdd, displayInitialEmpty } = settings;
	const [deleteField, setDeleteField] = useState<false | string>(false);
	const [initialAdded, setInitialAdded] = useState(false);
	let addTemplateId: string;
	const classes = useStyles();

	const handleAdd = (): void => {
		if (!addTemplateId) {
			console.error(`Dynamic Form: Error getting add template for repeating container with id ${ id }`);
			return;
		}
		addRepeatableField(id, addTemplateId, context);
	};

	useEffect(() => {
		const educationLegendElement = document.querySelector('legend[data-bi-id="quickform-form-section-for-education-legend"]');
		// Remove 'BhrTable__header--section' class from the education legend to fix the alignment issue
		if (educationLegendElement) {
			educationLegendElement.classList.remove('BhrTable__header--section');
		}
	})

	useEffect(() => {

		if (addTemplateId && Array.isArray(children) && children.length < 2 && displayInitialEmpty && !initialAdded) {
			return handleAdd();
		}
		setInitialAdded(true);
	}, [addTemplateId, displayInitialEmpty, children, initialAdded]);

	const handleRemove = (groupId: string): void => {
		if (groupId.includes('add')) {
			removeRepeatableField(id, groupId, context);
		} else {
			setDeleteField(groupId);
		}
	};

	const handleDeleteModalClose = (): void => {
		setDeleteField(false);
	};

	const handleDeleteModalAction = (): void => {
		if (typeof deleteField === 'string') {
			const groupId = deleteField;
			const educationId = groupId.split('_').pop();
			deleteEmployeeEducation(educationId, window.currentlyEditingEmployeeId.toString())
				.then(({ data = {} }) => {
					if (data.result === 'error') {
						window.setMessage(data.message, 'error');
						return;
					}
					window.setMessage(data.message, 'success');
					removeRepeatableField(id, groupId, context);
				})
				.catch(({ data = {} }) => {
					window.setMessage(data.message, 'error');
				});

		}
		setDeleteField(false);
	};

	const childrenWithoutPrototype = Array.isArray(children) ?
		children.map((child: React.ReactElement) => {
			if (
				child &&
					child.props.props &&
					child.props.props.className &&
					child.props.props.className.includes('repeatPrototype')
			) {
				addTemplateId = child.props.props.id;
				return null;
			}
			return (
				<EducationGroup
					{ ...child.props }
					key={ child.props.props.id }
					handleRemove={ handleRemove }
				/>
			);
		}) :
		children;

	return (
        <div>
            { deleteField && (
				<DeleteEducationModal
					handleClose={ handleDeleteModalClose }
					handlePrimaryAction={ handleDeleteModalAction }
				/>
			) }
            <div className="row repeat employee_education" id={ id }>
				<div>
					<input id="qf:employeeEducation" name="_qf__employeeEducation" type="hidden" />
					{ childrenWithoutPrototype }
				</div>

				<button
					className={`${classes.button} educationAdd fab-TextButton fab-link`}
					disabled={ !canAdd || disabled }
					id="educationAdd"
					onClick={ handleAdd }
					type="button"
				>
					<span className="fab-TextButton__icon fab-TextButton__icon--left">
						<IconV2 color="neutral-strong" name="circle-plus-regular" size={16}/>
					</span>
					{ $.__('Add Education') }
				</button>
			</div>
        </div>
    );
};

export default EducationRepeatContainer;
