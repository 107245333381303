import { Button, LayoutBox, TextButton } from '@fabric/button';
import { FunctionComponent, useState } from 'react';
import {
	determineValidEditableField,
	isSelectedPSSyncing,
	resetForm,
	scrollToError,
} from 'dynamic-form';

import { PageActionsFooter } from 'page-actions-footer.react';
import classnames from 'classnames';
import { isEnabled } from 'FeatureToggle.util';

const GENDER_TOGGLE = isEnabled('GenderSelectUpdates');

const Form: FunctionComponent<DynamicForm.FormElementProps> = ({
	props,
	children,
	settings,
	context,
}) => {
	const { className = '', id } = props;
	const {
		isClickToEdit = false,
		customFooterData = {
			primaryButton: {},
			secondaryButton: {},
		},
		validation = {},
	} = settings;
	const { primaryButton = {}, secondaryButton = {} } = customFooterData;
	const {
		form: { isEditable, setIsEditable, customOnSubmit, previewMode },
		validation: {
			trigger,
			formState: { errors },
		},
	} = context;
	const [isProcessing, setIsProcessing] = useState(false);

	const classes = classnames(...className.split(' '), {
		// TODO: If we are going to keep this, move it off of BhrForms namespace -> Personal Tabs
		'BhrForms--view': isClickToEdit ? !isEditable : false,
		DynamicForm__TraxSyncing: isSelectedPSSyncing(context),
	});

	const handleFormEditable = (e): void => {
		const isValidTag = determineValidEditableField(e);
		if (!isEditable && isValidTag) {
			setIsEditable(true);
		}
	};

	const handleSubmit = async () => {
		closeMessage();
		setIsProcessing(true);
		const isValid =
			validation.noValidation && GENDER_TOGGLE ? true : await trigger();
		console.log('isValid', isValid, errors);
		if (isValid) {
			const $form = $(`#${id}`);

			if (window.ASSUMED_USER) {
				window.disabledForPermsTest();
				return;
			}

			if (customOnSubmit) {
				customOnSubmit({
					context,
					submitForm: () => $form.submit(),
					onCancel: () => setIsProcessing(false),
				});
				return;
			}

			$form.submit();
		} else {
			setMessage(
				'Whoops... No worries. Please fix any missing or incorrect information and try again.',
				'error',
			);
			setIsProcessing(false);
			scrollToError();
		}
	};

	const handleCancel = (): void => {
		resetForm(context);
		if (isClickToEdit) {
			setIsEditable(false);
		}
	};

	return (
        <form {...props} className={classes} onClick={handleFormEditable}>
            <LayoutBox
				marginTop={id === 'emergencyContact' ? '-10px' : '-24px'}
			>
				{children}
				{!previewMode && (
					<PageActionsFooter isOpen={isEditable}>
						<Button
							onClick={handleSubmit}
							processing={isProcessing}
							type="submit"
						>
							{primaryButton.text || $.__('Save Changes')}
						</Button>
						<TextButton
							onClick={handleCancel}
							processing={isProcessing}
							type="reset"
						>
							{secondaryButton.text || $.__('Cancel')}
						</TextButton>
					</PageActionsFooter>
				)}
			</LayoutBox>
        </form>
    );
};

export default Form;
