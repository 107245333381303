import { FC } from 'react';
import { BlankState, Button } from '@bamboohr/fabric';
import { EMERGENCY_CONTACT_BLANK_TITLE, ADD_CONTACT, EmergencyBlankStateProps } from './constants';

export const EmergencyBlankDefault: FC<EmergencyBlankStateProps> = ({ onClick, canAdd }) => {
	return (
        <BlankState
			icon='bs-doc-missing-field'
			subtitle=''
			title={EMERGENCY_CONTACT_BLANK_TITLE}
			actions={canAdd && [
                <Button
                    color='secondary'
                    data-bi-id='employees-contacts-add-contact'
                    onClick={onClick}
                    startIcon='plus-regular'
                    type='button'
                >
                    {ADD_CONTACT}
                </Button>,
            ]}
		>
        </BlankState>
    );
};
