import { noop } from 'lodash';
import { Modal } from 'modal-legacy';
import { FunctionComponent } from 'react';
import { Flex } from '@bamboohr/fabric';

type Props = {
	handleClose: Function;
	handlePrimaryAction: Function;
};

const DeleteEducationModal: FunctionComponent<Props> = ({
	handleClose = noop,
	handlePrimaryAction = noop,
}) => {

	return (
        <Modal
			icon="trash-can-regular"
			iconColor="danger"
			iconV2Color="error-strong"
			isOpen={ true }
			onClose={ (): void => {
				handleClose();
			} }
			primaryAction={ (): void => {
				handlePrimaryAction();
			} }
			primaryActionText={ $.__('Yes, Delete Education') }
			title={ $.__('Delete Education') }
			headline={$._('Are you sure you want to delete this education record?')}
			content={<Flex justifyContent={'center'}>{$.__(`Don't worry, doing so won't actually make the employee any less educated.`)}</Flex>}
		>
        </Modal>
    );
};

export default DeleteEducationModal;
