import { FC } from 'react';
import {
	BlankState,
	Button,
} from '@bamboohr/fabric';
import {
	EMERGENCY_CONTACT_BLANK_TITLE,
	ADD_CONTACT,
	EmergencyBlankStateProps,
} from './constants';

export const EmergencyBlankNewEmployee: FC<EmergencyBlankStateProps> = ({ onClick, canAdd }) => {
	return (
        <BlankState
			icon="bs-doc-missing-field"
			subtitle={ EMERGENCY_CONTACT_BLANK_TITLE }
			title=""
			actions={canAdd && [
                <Button
                    color='secondary'
                    data-bi-id='employees-contacts-add-contact'
                    onClick={onClick}
                    startIcon='plus-regular'
                    type='button'
                >
                    {ADD_CONTACT}
                </Button>,
            ]}
		>
        </BlankState>
    );
};
